import React from 'react'
import Toggle from './Toggle/Toggle'
import './Navbar.css'
const Navbar = () => {
  return (
    <div className='n-wrapper'>
        <div className="n-left">
            <div className="n-name">Andrew</div>
            <Toggle/>
        </div>
        <div className="n-right">
            <div className="n-list">
                <ul>
                    <li>Home</li>
                    <li>Services</li>
                    <li>Portfolio</li>
                    <li>Experiences</li>
                    <li>Testimonials</li>
                </ul>
            </div>
            <div className="button n-button">
                Contact
            </div>   
        </div>
    </div>
  )
}

export default Navbar
