import React from "react";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Heartemoji from "../../img/heartemoji.png";
import './Services.css'
import Card from "./Card/Card";
import CV from "./CV.jpg"
import { motion } from "framer-motion"

const Services = () => {

  const transition = {duration : 1, type : 'spring'}

  return (
    <div className="services">
      {/*  */}
      <div className="awesome">
        <span>My Awesome</span>
        <span>services</span>
        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, vero.
        <br/>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, ut.
        </span>
        <a href={CV} download>
          <button className="button s-button">Download CV</button>
          </a>
        
        <div className="blur s-blur1" style={{background: "#ABF1FF94"}}></div>
      </div>
      {/* cards */}
      <div className="cards">
        <motion.div 
        initial={{left: '25rem'}}
        whileInView={{left: '14rem'}}
        transition={transition}
        
        style={{left: '14rem'}}>
          <Card
          emoji={Heartemoji}
          heading={'Design'}
          detail={"Figma, Sketch, Photoshop, Adobe, Adobe xd"}
          />
        </motion.div>
        {/* second card */}
        <motion.div
        initial={{left: '-10rem'}}
        whileInView={{top: '12rem', left: '-4rem'}}
         style={{top: '12rem', left: '-4rem'}}>
          <Card
          emoji={Glasses}
          heading={"Developer"}
          detail={"Html, css, javscript, react"}
          />
        </motion.div>
        {/* third card */}
        <motion.div
        initial={{left: '16rem'}}
        whileInView={{top: "19rem", left:'12rem'}}
        style={{top: "19rem", left:'12rem'}}>
        <Card
          emoji={Humble}
          heading={'UI/UX'}
          detail={'lorem ipsum dummy text are usually in secton where we need some random text'}
          />
        </motion.div>
        <div className="blur s-blur2" style={{background: 'var(--purple)'}}></div>
      </div>
    </div>
  );
};

export default Services;
